var __jsx = React.createElement;
import { FormControl } from '@chakra-ui/react';
import { Box, HStack, PasswordInput, ProBadgeIcon, Switch, Tooltip } from '@playful/design_system';
import React, { useEffect, useState } from 'react';
import { useProject, useProjectPermissions } from '../hooks/useProject';
import { useProjectSettingsContext } from './ProjectSettingsContext';
import { SwitchField } from './SwitchField';
import { SwitchLabel } from './SwitchLabel';
export var PasswordProtectSetting = function PasswordProtectSetting() {
  var _useProjectSettingsCo = useProjectSettingsContext(),
    isLoading = _useProjectSettingsCo.isLoading,
    projectInfo = _useProjectSettingsCo.projectInfo;
  var _useState = useState(false),
    hasPassword = _useState[0],
    setHasPassword = _useState[1];
  var _useState2 = useState((projectInfo === null || projectInfo === void 0 ? void 0 : projectInfo.password) || ''),
    password = _useState2[0],
    setPassword = _useState2[1];
  var _useProject = useProject({
      id: projectInfo.id
    }),
    updatePassword = _useProject.updatePassword;
  var _useProjectPermission = useProjectPermissions({
      id: projectInfo.id
    }),
    setShared = _useProjectPermission.setShared,
    isShared = _useProjectPermission.isShared;
  useEffect(function () {
    setHasPassword(!!(projectInfo !== null && projectInfo !== void 0 && projectInfo.password.length));
    setPassword((projectInfo === null || projectInfo === void 0 ? void 0 : projectInfo.password) || '');
  }, [projectInfo === null || projectInfo === void 0 ? void 0 : projectInfo.password]);
  function handlePasswordProtectionChange(e) {
    setHasPassword(e.target.checked);
    if (!e.target.checked) {
      updatePassword('');
    }
  }
  function handleUpdatePassword() {
    updatePassword(password);
    if (isShared) {
      setShared(false);
    }
  }
  return __jsx(Box, {
    w: "100%"
  }, __jsx(SwitchField, null, __jsx(Box, {
    pr: 4
  }, __jsx(SwitchLabel, {
    htmlFor: "password-protect",
    tooltipLabel: 'Add a password to your project.'
  }, "password protected")), __jsx(HStack, {
    spacing: 2
  }, __jsx(Tooltip, {
    hasArrow: true,
    offset: [0, 12],
    placement: "top",
    label: "Add a password to your project."
  }, __jsx(Box, {
    as: "span",
    cursor: "help"
  }, __jsx(ProBadgeIcon, {
    h: 5,
    w: 5,
    "data-testid": 'password-protect-pro-badge'
  }))), __jsx(Switch, {
    "aria-label": "password protection",
    id: "password-protect",
    size: "md",
    isDisabled: isLoading,
    onChange: handlePasswordProtectionChange,
    isChecked: hasPassword
  }))), hasPassword && __jsx(FormControl, {
    mt: 1,
    isInvalid: password.length < 1
  }, __jsx(PasswordInput, {
    variant: "filled",
    value: password,
    onChange: function onChange(e) {
      return setPassword(e.target.value);
    },
    onBlur: function onBlur() {
      return handleUpdatePassword();
    }
  })));
};