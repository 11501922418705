var __jsx = React.createElement;
import { FormHelperText } from '@chakra-ui/react';
import { FormControl, Input, Select } from '@playful/design_system';
import { slugify } from '@playful/utils';
import React from 'react';
import { ChooseSlug } from './ChooseSlug';
import { getDefaultDomain } from './ProjectSettings';
import { useProjectSettingsContext } from './ProjectSettingsContext';
import { ProjectSettingsFormLabel } from './ProjectSettingsLayout';
export var HATCH_DOMAIN_TOKEN = 'hatch-domain';
export function ChooseDomainAndPath() {
  var _useProjectSettingsCo = useProjectSettingsContext(),
    customDomains = _useProjectSettingsCo.customDomains,
    onDomainChange = _useProjectSettingsCo.onDomainChange,
    projectInfo = _useProjectSettingsCo.projectInfo,
    updatedDomain = _useProjectSettingsCo.updatedDomain,
    isUpdatedDomainCustom = _useProjectSettingsCo.isUpdatedDomainCustom,
    path = _useProjectSettingsCo.path,
    onPathChange = _useProjectSettingsCo.onPathChange,
    pathErrMsg = _useProjectSettingsCo.pathErrMsg,
    domainErrMsg = _useProjectSettingsCo.domainErrMsg,
    pathInvalid = _useProjectSettingsCo.pathInvalid,
    pathInputValue = _useProjectSettingsCo.pathInputValue,
    setPathInputValue = _useProjectSettingsCo.setPathInputValue;
  return __jsx(React.Fragment, null, __jsx(FormControl, null, __jsx(ProjectSettingsFormLabel, {
    htmlFor: 'select-domain'
  }, "domain"), __jsx(Select, {
    "data-testid": 'select-domain',
    id: 'select-domain',
    variant: "filled",
    onChange: handleDomainChange,
    value: updatedDomain
  }, __jsx("option", {
    value: HATCH_DOMAIN_TOKEN
  }, getDefaultDomain(projectInfo).host), customDomains.map(function (domain) {
    return __jsx("option", {
      key: domain.name,
      value: domain.name
    }, domain.name);
  })), domainErrMsg && __jsx(FormHelperText, {
    color: 'red'
  }, domainErrMsg)), isUpdatedDomainCustom && __jsx(FormControl, {
    isInvalid: pathInvalid,
    my: 4
  }, __jsx(ProjectSettingsFormLabel, {
    htmlFor: 'customize-domain',
    mb: 1
  }, "customize url"), __jsx(Input, {
    variant: "filled",
    value: pathInputValue,
    onChange: handlePathChange
  }), __jsx(FormHelperText, {
    color: pathInvalid ? 'red' : 'gray.500'
  }, pathInvalid ? pathErrMsg : "".concat(updatedDomain, "/").concat(path))), !isUpdatedDomainCustom && __jsx(ChooseSlug, {
    label: 'customize url'
  }));
  function handleDomainChange(e) {
    var newDomain = e.target.value;
    onDomainChange(newDomain);
  }
  function handlePathChange(event) {
    setPathInputValue(event.target.value);
    onPathChange(slugify(event.target.value));
  }
}