export var ROUTE_HOME = '/';
export var ROUTE_SIGNIN = '/signin';
export var ROUTE_FORGOT_PASSWORD = '/forgot-password';
export var ROUTE_WORKBENCH = '/workbench';
export var INTERNAL_ROUTE_DELETE_TEST_USER = '/internal-delete-test-user';
export var ROUTE_FORUM = '/forum';
export var ROUTE_TUTORIALS = '/tutorials';
export var ROUTE_FAQS = '/faqs';
export var ROUTE_CONTACT = '/contact';
export var ROUTE_ABOUT = '/about-us';
export var ROUTE_CAREERS = '/work-here';
export var ROUTE_BLOG = '/blog';
export var ROUTE_SUPPORT = '/support';
export var ROUTE_MY_ACCOUNT = '/account';
export var ROUTE_TEMPLATES = '/templates';
export var ROUTE_WHATS_NEW = '/whats-new';
export var ROUTE_WALKTHROUGH = '/walkthrough';