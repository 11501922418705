import _extends from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import { useBreakpointValue } from '@chakra-ui/react';
import { Box, BrowseTemplatesIcon, Button, ChevronDownIcon, ChevronUpIcon, IconButton, MenuButton, MenuIcon, PagesIcon, useIsSmallScreen, Text } from '@playful/design_system';
import React from 'react';
import { MenuSubMenu } from '../components/MenuSubMenu';
import { darkBlankProjectRoute, lightBlankProjectRoute } from '../components/TemplatePickers/routes.js';
import { useCleanPath } from '../hooks/useCleanPath';
import { ROUTE_TEMPLATES } from '../routes';
import { AuthLink } from './AuthFlow/AuthLink';
import { Avatar } from './Avatar';
import ProProfileBadge from './pro-profile-badge.png';
import { useNavigationLinks } from './useNavigationLinks';
import { useUserContext } from './UserContext';
import { useRouter } from '../hooks/useRouter';
var UserSettingsMenuButton = function UserSettingsMenuButton(_ref) {
  var isOpen = _ref.isOpen,
    onOpen = _ref.onOpen;
  var _useUserContext = useUserContext(),
    user = _useUserContext.user,
    hasActiveSubscription = _useUserContext.hasActiveSubscription;
  return __jsx(MenuButton, {
    as: IconButton,
    "data-cy": "account-button",
    "aria-label": "My Account",
    "aria-owns": isOpen ? 'menu-appbar' : undefined,
    "aria-haspopup": "true",
    variant: "ghost",
    onClick: onOpen,
    colorScheme: "inverse",
    position: "relative",
    isRound: true
  }, __jsx(Avatar, {
    email: user.email,
    userName: user.name
  }), hasActiveSubscription && __jsx(Box, {
    position: "absolute",
    bottom: 0,
    right: '-4px'
  }, __jsx("img", {
    height: "17px",
    width: "18px",
    src: ProProfileBadge.src,
    alt: "Hatch Pro"
  })));
};
var MobileMenuButton = function MobileMenuButton(_ref2) {
  var onOpen = _ref2.onOpen;
  return __jsx(MenuButton, {
    as: IconButton,
    "aria-label": "mobile navigation menu",
    onClick: onOpen,
    colorScheme: "dark",
    variant: "ghost",
    icon: __jsx(MenuIcon, null),
    size: "sm",
    "data-cy": "mobile-menu-button"
  });
};
var NewProjectMenuButton = function NewProjectMenuButton(_ref3) {
  var label = _ref3.label,
    withArrow = _ref3.withArrow,
    isOpen = _ref3.isOpen,
    onClose = _ref3.onClose,
    onOpen = _ref3.onOpen;
  var icon = isOpen ? __jsx(ChevronUpIcon, null) : __jsx(ChevronDownIcon, null);
  var buttonSize = useBreakpointValue({
    base: 'sm',
    lg: 'md'
  }, {
    fallback: 'lg'
  });
  return __jsx(MenuButton, {
    as: Button,
    variant: "rebrand-pill",
    rightIcon: withArrow ? icon : undefined,
    onClick: function onClick() {
      return isOpen ? onClose === null || onClose === void 0 ? void 0 : onClose() : onOpen === null || onOpen === void 0 ? void 0 : onOpen();
    },
    size: buttonSize,
    colorScheme: 'brand',
    sx: {
      svg: {
        h: 5,
        w: 5
      }
    },
    "data-cy": 'newProject'
  }, label);
};
export function AccountAppBarSection() {
  var _useUserContext2 = useUserContext(),
    isLoggedInUser = _useUserContext2.isLoggedInUser;
  var isSmallScreen = useIsSmallScreen();
  var _useRouter = useRouter(),
    push = _useRouter.push,
    asPath = _useRouter.asPath;
  var cleanedPath = useCleanPath(asPath);
  var _useNavigationLinks = useNavigationLinks(),
    getUserProfileRoutes = _useNavigationLinks.getUserProfileRoutes,
    getAllRoutes = _useNavigationLinks.getAllRoutes;
  var userRoutes = getUserProfileRoutes();
  var allRoutes = getAllRoutes();
  var buttonSize = useBreakpointValue({
    base: 'sm',
    lg: 'md'
  }, {
    fallback: 'lg'
  });
  var newProjectRouteItems = {
    label: 'New project',
    items: [{
      label: __jsx(Text, {
        as: "span",
        display: "flex",
        gap: 2
      }, __jsx(BrowseTemplatesIcon, null), __jsx(Text, {
        as: "span"
      }, "Browse templates")),
      path: ROUTE_TEMPLATES
    }, {
      label: __jsx(Text, {
        as: "span",
        display: "flex",
        gap: 2,
        cursor: 'pointer'
      }, __jsx(PagesIcon, {
        gap: 2
      }), __jsx(Text, {
        as: "span"
      }, "Blank project")),
      onClick: function onClick(event) {
        if (event !== null && event !== void 0 && event.shiftKey) {
          push(darkBlankProjectRoute);
        } else {
          push(lightBlankProjectRoute);
        }
      }
    }]
  };
  return __jsx(React.Fragment, null, isLoggedInUser ? __jsx(React.Fragment, null, cleanedPath !== ROUTE_TEMPLATES && __jsx(MenuSubMenu, {
    TopMenuButton: NewProjectMenuButton,
    label: newProjectRouteItems.label,
    items: newProjectRouteItems.items,
    placement: "left"
  })) : __jsx(React.Fragment, null, __jsx(Button, {
    as: AuthLink,
    "data-cy": "sign-in",
    role: "button",
    authType: 'signIn',
    size: buttonSize,
    fontSize: {
      base: 'sm',
      md: 'md'
    },
    variant: "rebrand-pill-ghost",
    colorScheme: "inverse"
  }, "Log in")), isLoggedInUser && userRoutes && !isSmallScreen && __jsx(MenuSubMenu, _extends({
    size: "lg",
    TopMenuButton: UserSettingsMenuButton
  }, userRoutes, {
    placement: "left"
  })), allRoutes && isSmallScreen && __jsx(Box, {
    ml: 2
  }, __jsx(MenuSubMenu, {
    TopMenuButton: MobileMenuButton,
    label: allRoutes.label,
    items: allRoutes.items,
    placement: "bottom-end",
    size: "sm"
  })));
}