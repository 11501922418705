var __jsx = React.createElement;
import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Text, Link } from '@playful/design_system';
import { ListItem, UnorderedList } from '@chakra-ui/react';
import React from 'react';
export default function SunsetMessageModal(_ref) {
  var isOpen = _ref.isOpen,
    onClose = _ref.onClose;
  // Check if user has seen the message before
  if (sessionStorage.getItem('sunsetMessageSeen')) {
    return null;
  }
  var handleClose = function handleClose() {
    sessionStorage.setItem('sunsetMessageSeen', 'true');
    onClose();
  };
  return __jsx(Modal, {
    isOpen: isOpen,
    onClose: handleClose,
    isCentered: true
  }, __jsx(ModalOverlay, null), __jsx(ModalContent, {
    maxW: '685px',
    py: 4
  }, __jsx(ModalBody, null, __jsx(ModalHeader, null, "Hatch Sunset"), " ", __jsx(ModalCloseButton, null), __jsx(ModalBody, {
    gap: 4,
    display: 'flex',
    flexDirection: 'column'
  }, __jsx(Text, {
    fontWeight: 'bold'
  }, "To our valued Hatch community,"), __jsx(Text, null, "We\u2019ve spent over three years exploring the possibilities of web creativity and are grateful for everyone who has joined us in that adventure. Every site made with Hatch has reinforced our belief in making the web a more playful, interactive space."), __jsx(Text, null, "As our product has evolved with the web, we\u2019ve made the difficult decision to sunset the Hatch website builder. All Hatch web projects will be officially shut down on", ' ', __jsx(Text, {
    as: 'span',
    fontWeight: 'bold'
  }, "Friday, February 28, 2025"), "."), __jsx(Text, null, "Here\u2019s what you need to know:"), __jsx(UnorderedList, {
    pl: 4
  }, __jsx(ListItem, null, "You will have access to Pro features. Subscriptions have been canceled and will not renew."), __jsx(ListItem, null, "Yearly subscribers will receive a pro-rated refund when we sunset on Feb 28, 2025."), __jsx(ListItem, null, "Projects and published pages will remain fully functional until Feb 28, 2025."), __jsx(ListItem, null, "Ownership of custom domains acquired through Hatch\u2019s integration with Hover is not affected."), __jsx(ListItem, null, "You have until Feb 28, 2025 to export your project assets."), __jsx(ListItem, null, "If you have questions not answered in", ' ', __jsx(Link, {
    href: 'https://hatch.one/sunset/faqs'
  }, "Sunset FAQs"), ", please reach out to us through ", __jsx(Link, {
    href: 'https://hatch.one/support'
  }, "hatch.one/support"), ". Our support team will be available through Feb 28, 2025 to assist you.")), __jsx(Text, null, "We\u2019re turning the page at Hatch, but this isn\u2019t the end of our story. Our next chapter harnesses AI to reimagine what we can make\u2013 and do\u2013 together. Sign up below to be part of the future of Hatch."), __jsx(Text, null, "With gratitude,", __jsx("br", null), "Mike, Darrin, and the Hatch team")))));
}